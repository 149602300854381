var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[(_vm.canAddCarDealer)?_c('v-btn',{staticClass:"mb-0",attrs:{"color":"primary","to":{ name: 'CarDealer Create' }}},[_vm._v(" "+_vm._s(_vm.$t('btn.add_car_dealer'))+" ")]):_vm._e()],1)],2),_c('v-container',{attrs:{"id":"car-dealer-list","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 pt-3 mb-15",attrs:{"dark":_vm.$vuetify.theme.dark,"inline":"","color":"primary","icon":"mdi-garage-variant"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-card-title',{staticClass:"pt-0 pb-2"},[_c('div',[_vm._v(_vm._s(_vm.$t('car_dealer.list.title')))])])]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.carDealerList,"options":_vm.paginationOptions,"hide-default-footer":""},on:{"update:sort-by":_vm.onSortBy,"update:sort-desc":_vm.onSortDesc},scopedSlots:_vm._u([{key:"header.car",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListSelectFilter',{attrs:{"multiple":"","translation":_vm.$t('car_dealer.list.filter.region'),"value":_vm.filters.region,"items":_vm.$appConfig.carDealerRegions},on:{"filter-list":_vm.filterByRegion}})],1)]}},{key:"header.code",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t('car_dealer.list.filter.code'),"value":_vm.filters.code},on:{"filter-list":_vm.filterByCode}})],1)]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t('car_dealer.list.filter.name'),"value":_vm.filters.name},on:{"filter-list":_vm.filterByName}})],1)]}},{key:"header.brand",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListSelectFilter',{attrs:{"multiple":"","translation":_vm.$t('car_dealer.list.filter.brand'),"value":_vm.filters.brand,"items":_vm.$appConfig.brands},on:{"filter-list":_vm.filterByBrand}})],1)]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [(item.address)?[_vm._v(" "+_vm._s(item.address.name)+" ")]:_vm._e()]}},{key:"item.regionRencontres",fn:function(ref){
var item = ref.item;
return [(item.regionRencontres)?[_vm._v(" "+_vm._s(item.regionRencontres.name)+" ")]:_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center ma-2 rounded"},[_c('v-avatar',{staticClass:"rounded-lg",attrs:{"tile":"","color":"success"}},[_c('v-icon',{on:{"click":function($event){return _vm.goToCarDealerForm(item)}}},[_vm._v(" mdi-pencil ")])],1)],1)]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination-options":_vm.paginationOptions,"nb-pages":_vm.nbPages},on:{"changePaginationOptions":function($event){_vm.paginationOptions = $event}}})]},proxy:true}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }